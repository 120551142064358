define('m03/routes/application', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({

    model: function model() {
      return Ember['default'].RSVP.hash({
        application: this.store.findAll('application'),
        projektdaten: this.store.findAll('projektdaten'),
        geometrie: this.store.findAll('geometrie'),
        bauteile: this.store.findAll('bauteile'),
        zwischenschichtverstaerkung: this.store.findAll('zwischenschichtverstaerkung'),
        anschlussgeometrie: this.store.findAll('anschlussgeometrie'),
        optionen: this.store.findAll('optionen'),
        lasteinwirkung: this.store.findAll('lasteinwirkung'),
        x3ddefault: this.store.findAll('x3ddefault')

      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.watchingTextSize(controller, false);
    },

    watchingTextSize: function watchingTextSize(controller, loaded) {
      var self = this;

      loaded = document.getElementById('x3d') !== null ? true : loaded;

      if (loaded) {
        (function () {
          var setX3DSize = function setX3DSize() {
            var x3dGrafik = document.getElementById("threedee");
            var x3d = document.getElementById("x3d");
            var canvas = document.getElementsByTagName("canvas");
            var x3druntime = document.getElementById('x3d').runtime;

            x3d.setAttribute('width', Number($(window).width()) - Number(x3dGrafik.offsetLeft) - 20);
            x3d.setAttribute('height', Number($(window).height()) - 300);
          };

          document.getElementById('x3d').runtime.enterFrame = function () {
            var vp = controller.model.x3ddefault.objectAt(0).get('lastViewpoint');
            var skalfak = Math.abs(document.getElementById(vp).getFieldValue('fieldOfView')[0]) / 4;

            controller.model.x3ddefault.objectAt(0).set('skalierungsfaktor', skalfak);
            // let texts = document.getElementsByTagName('text');
            // let str = '';
            // for (let j = 0; j < texts.length; j++) {
            //   str = texts[j].getAttribute('string');
            //   // texts[j].setAttribute('string', ' ');
            //   // texts[j].setAttribute('string', str);
            // }
          };

          $(document).ready(function () {
            setX3DSize();
          });

          $(window).resize(function () {
            setX3DSize();
          });
        })();
      } else {
        var debounce = Ember['default'].run.debounce(function () {

          try {

            document.getElementById('x3d').runtime.enterFrame = function () {};
          } catch (error) {
            console.log('/routes/application.js: x3dom need reload');
            x3dom.reload();
            self.watchingTextSize(controller, false);
          }
        }, 500);
      }
    }

  });

});